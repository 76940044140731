import React from 'react';
import "../assets/css/main.css";
import { Typography, Container, Paper } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { styled } from '@mui/material/styles';
import ResponsiveAppBar from '../components/ResponsiveAppBar';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css'; // Import styles for the carousel
import imgPath from '../assets/img/dhanHO.jpeg';
import teamImg from '../assets/img/team.jpg';
import valuesImg from '../assets/img/Values.jpg';
import PageFooter from '../components/PageFooter';
import CardCarousel from '../components/CardCarousel';

const MainContainer = styled(Container)(({ theme }) => ({
  marginBottom: theme.spacing(4), // Adjust the spacing value as needed
}));

const AboutPage = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    largeDesktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
    },
    desktop: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const SampleImage = styled('img')({
    width: '100%',
  height: 'auto',
    borderRadius: '8px',
    alignContent:'center'
  });
  

  const TextOverlay = styled(Typography)(({ theme }) => ({
    position: 'absolute',
    top: '50%',
    left: '30%',
    fontSize:'auto',
    transform: 'translate(-50%, -50%)',
    color: 'rgba(0, 0, 0, 0.5)',
  //   backgroundColor: 'rgba(0, 0, 0, 0.5)',
    padding: theme.spacing(1),
    borderRadius: '8px',
    textAlign: 'center',
  }));

  const ImageContainer = styled('div')({
    position: 'relative',
    textAlign: 'left',
    color: 'White',
    marginBottom: '16px',
  });
  


  return (
    <>
    
      <ResponsiveAppBar />
   
        {/* <Container> */}
          <ImageContainer>
            <SampleImage src={teamImg} alt="Contact Us Image" />
            <TextOverlay variant="h5">About Us</TextOverlay>
          </ImageContainer>
          <Typography variant="caption" align="left"></Typography>
        {/* </Container> */}
    
      <MainContainer>
      <section>
      <Typography variant="h5" component="h3" gutterBottom>
        About company
      </Typography>
      <Typography variant="body1" sx={{color:'#5a4a4a'}} paragraph>
      Established in 2020, Sree Dhanalakshmi Enterprises is a diversified business focused on marketing, 
      trading, buying, selling, importing, exporting, and distributing a wide array of household goods and 
      commodities. Our product offerings include top-quality electronic devices such as laptops, desktops, 
      tablets, and mobile phones, catering to both retail and wholesale markets. Through robust market analysis, 
      consumer surveys, and a range of promotional activities—including events, contests, and seminars—we 
      ensure our services and products resonate with the needs of our customers across India and beyond.
In addition to our product-based services, Sree Dhanalakshmi Enterprises offers specialized consultancy in 
business development, strategic marketing, talent acquisition, and human resource management. 
We are dedicated to empowering businesses by delivering valuable insights, targeted growth strategies, 
and support in building capable teams to drive operational success.
Moreover, we proudly serve as Business Correspondents for Private Limited Companies, Public Limited Companies, 
Partnership Firms, Limited Liability Partnerships, Multi-State Credit Co-operative Societies, and various 
registered entities. In this role, we provide essential financial products, transaction support, and 
strategic market insights, helping our clients expand their reach and meet business objectives with precision.
Our mission is to be a dependable partner across all dimensions of commerce and consultancy, consistently 
delivering value through expertise, innovation, and commitment to client success.
      </Typography>
      
      <Typography variant="body1" sx={{color:'#5a4a4a'}}paragraph>
        Thank you for visiting our website. We look forward to serving you!
      </Typography>
    
      </section>
           {/* <footer className="footer">
          <p>Dhanalakshmi Hire Purchase & Leasing Ltd | Non-Banking Financial Company</p>
          <p>&copy; 2024 Dhanalakshmi Hire Purchase & Leasing Ltd. All rights reserved.</p>
          <p>Powered By :IT DEPARTMENT DHANFIN</p>
        </footer> */}
 <section className="event-details">
            {/* <EventDetailsContainer> */}
              <CardCarousel/> 
            {/* </EventDetailsContainer> */}
          </section>
      
</MainContainer>
    {/* <footer className="footer">
          <p>Dhanalakshmi Hire Purchase & Leasing Ltd | Non-Banking Financial Company</p>
          <p>&copy; 2024 Dhanalakshmi Hire Purchase & Leasing Ltd. All rights reserved.</p>
          <p>Powered By :IT DEPARTMENT DHANFIN</p> */}
        <PageFooter/>
    </>
  );
};

export default AboutPage;
