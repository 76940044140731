import React from 'react';
import { Card, CardContent, Typography, Box ,Link} from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledCard = styled(Card)(({ theme }) => ({
  maxWidth: 400,
  margin: 'auto',
  padding: theme.spacing(3),
  boxShadow: '0 4px 8px rgba(0, 0, 1, 0.1)',
  borderRadius: '15px',
}));

const ContactCard = () => {
  return (
    <Box mt={4} display="flex" justifyContent="center">
      <StyledCard>
        <CardContent>
          {/* <Typography variant="h5" component="div" gutterBottom>
            Contact Us
          </Typography> */}
          {/* <Typography variant="body1" color="textSecondary" gutterBottom>
            For more details, feel free to reach out to us.
          </Typography> */}
          {/* <Typography variant="body2" color="textSecondary">
            Email: events@company.com
          </Typography> */}
          {/* <Typography variant="h6" color="textSecondary">
          Contact: <Link href="tel:+919188924426" color="primary">+919188924426</Link>
          </Typography> */}
          <Typography variant="h6" color="textSecondary">
          Email: <Link href="Email:dhanfinenterprises.com" color="primary">info@dhanfinenterprises.com</Link>
          </Typography>
        </CardContent>
      </StyledCard>
    </Box>
  );
};

export default ContactCard;
