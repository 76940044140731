import React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import Adsbanner1 from '../assets/img/BannerAds/MIBL_Website_Banner1.jpg';
import Adsbanner2 from '../assets/img/BannerAds/MIBL_Website_Banner2.jpg';
import Adsbanner3 from '../assets/img/BannerAds/MIBL_Website_Banner3.jpg';
import Adsbanner4 from '../assets/img/BannerAds/MIBL_Website_Banner4.jpg';
import Adsbanner5 from '../assets/img/BannerAds/MIBL_Website_Banner5.jpg';
import { Container } from '@mui/material';


const CardCarouselLarge = [
  {
    // label: 'SAMSUNG 21',
    imgPath:Adsbanner1,
    // c: '512',
    // desc: 'This is a TV of 32 inch, not for you',
  },
  {
    //label: 'Bird',
    imgPath:Adsbanner2,
    // c: '312',
    //desc: 'This is a TV of 32 inch, not for you',
  },
  {
    //label: 'Bali, Indonesia',
    imgPath:Adsbanner3,
      //  c: '412',
    //desc: 'This is a TV of 32 inch, not for you',
  },
  {
    //label: 'NeONBRAND Digital Marketing, Las Vegas, United States',
    imgPath:Adsbanner4,
     //   c: '132',
    //desc: 'This is a TV of 32 inch, not for you',
  },
  {
    //label: 'Goč, Serbia',
      imgPath:Adsbanner5,    c: '112',
    //desc: 'This is a TV of 32 inch, not for you',
  },


];

const Root = styled('div')(({ theme }) => ({
  margin: '1px 1px',
  display: 'flex',
  flexDirection: 'column',
  // justifyContent: 'center', // Center the items
  // alignItems: 'center',
}));

const Media = styled(CardMedia)({
  height: 150,
  objectFit: 'cover',
});

const MediaContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
});

const Typo = styled(Typography)({
  textAlign: 'center',
});

const MyCard = styled(Card)({

  width: 580,
  height:200,
});

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    width: 'auto',
    height:'auto',
    margin:2,
    items: 1,
  },
};

const Cards = () => {
  return (
    <Carousel
      responsive={responsive}
      autoPlay={true}
      autoPlaySpeed={1800} // Adjust the speed as needed (3000ms = 3s)
      infinite={true} // Enable infinite looping
    >
      {CardCarouselLarge.map((item, i) => (
        <CardSwipeable key={i} item={item} />
      ))}
    </Carousel>
  );
};

const CardSwipeable = (props) => {
  return (
    <Root>
      <MyCard>
        <CardActionArea >
          <MediaContainer>
          <CardMedia
         justifyContent= 'flex-center'
          component="img"
          height="200" image={props.item.imgPath} title={props.item.label} />
          </MediaContainer >
          <CardContent>
            <Typo gutterBottom variant="h6" component="h6" sx={{fontSize:15, color: '#eb1f28' }}>
           Insurance Products {props.item.c}
            </Typo>
            <Typo variant="h5" component="h3" sx={{fontSize:15, color: '#eb1f28', margin: '6px 0px' }}>
              {props.item.label}
            </Typo>
            <Typo color="textSecondary" component="p" sx={{fontSize:12, margin: '1px 1px' }}>
              {props.item.desc}
            </Typo>
          </CardContent>
        </CardActionArea>
      </MyCard>
    </Root>
  );
};

export default Cards;