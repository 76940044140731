import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Card, CardContent, CardMedia, Typography, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

// Define fallback colors if the gradient isn't defined in your theme
const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
  boxShadow: theme.shadows[3],
}));

const TimeSection = styled(CardContent)(({ theme }) => ({
  background: 'linear-gradient(to right, #eb1f28, #f37585)', // Fallback gradient
  color: '#fff',
  textAlign: 'center',
  fontWeight: 'bold',
  fontSize: '1.5rem',
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
}));

const ContentSection = styled(CardContent)(({ theme }) => ({
  position: 'relative',
  padding: theme.spacing(2),
}));

const SpeakerInfo = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2),
}));

const ProfileImage = styled(CardMedia)({
  borderRadius: '50%',
  width: '100%',
  height: '100%',
  objectFit: 'cover',
});

const ScheduleCard = ({ scheduleData }) => {
  const { time, place_onsite, place_online } = scheduleData.schedule;
  const { speaker_name, company, profile_pic } = scheduleData.speaker;
  const { name, id } = scheduleData;

  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <Link to={`/event/${id}`} style={{ textDecoration: 'none' }}>
        <StyledCard>
          <TimeSection>
            <Typography variant="h6">{time}</Typography>
          </TimeSection>
          <ContentSection>
            <Typography variant="h6" gutterBottom>
              {name}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {place_onsite && (
                <Typography component="div" variant="body2">
                  Venu: {place_onsite}
                </Typography>
              )}
              {place_online && (
                <Typography component="div" variant="body2">
                  Details: {place_online}
                </Typography>
              )}
            </Typography>
          </ContentSection>
          <SpeakerInfo>
            <ProfileImage
              component="img"
              src={profile_pic}
              alt={speaker_name}
            />
            <div style={{ marginLeft: '16px' }}>
              <Typography variant="body2" component="p">
                {speaker_name}
                <br />
                {company}
              </Typography>
            </div>
          </SpeakerInfo>
        </StyledCard>
      </Link>
    </Grid>
  );
};

ScheduleCard.propTypes = {
  scheduleData: PropTypes.shape({
    schedule: PropTypes.shape({
      time: PropTypes.string.isRequired,
      place_onsite: PropTypes.string,
      place_online: PropTypes.string,
    }).isRequired,
    speaker: PropTypes.shape({
      speaker_name: PropTypes.string.isRequired,
      company: PropTypes.string.isRequired,
      profile_pic: PropTypes.string.isRequired,
    }).isRequired,
    name: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
  }).isRequired,
};

export default ScheduleCard;
