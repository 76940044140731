import ScheduleCard from "./ScheduleCard/ScheduleCard";
import { Grid,LinearProgress,Box } from '@mui/material';

const Schedule = ({ scheduleData }) => {
  return (
    <div className="md:max-w-4xl mb-20 mx-auto mt-20">
      <div className="p-5 text-center">
        <h2 className="text-4xl  text-rose-400 font-bold " style={{color:'#eb1f28'}}>
        Products & Services
        </h2>
        <span>
        <Box sx={{ width: '100%', marginBottom: 2  }}>
          {/* <progress className="progress progress-error w-32"></progress> */}
          <LinearProgress variant="indeterminate" value={50} color="secondary" />
          </Box>
        </span>
        
      </div>
      <Grid container spacing={2}>
        {scheduleData?.map((data) => (
          <ScheduleCard key={data.id} scheduleData={data} />
        ))}
      </Grid>
    </div>
  );
};

export default Schedule;
