import * as React from "react";
import PropTypes from "prop-types";
import { styled } from '@mui/material/styles';
import { Container, Box, Grid, Paper, Typography } from '@mui/material';
const branchList = [
  {
    label: "AYYANTHOLE (Live)",
    desc: (
      <p>
        REVATHI SHOPPING COMPLEX, 2ND FLOOR, ROOM NO: (51/1717) (18/509/9),
        AYYANTHOLE CIVIL LANE, THRISSUR, KERALA-680003
      </p>
    ),
  },
  {
    label: "GURUVAYOOR",
    desc: (
      <p>
        SOUTH SIDE OF THE FIRST FLOOR, ROOM NO. XXVII/369 AND XXVII/370 (OLD
        NUMBERS 19/100/8 AND 19/100/9, WIDE REGENCY BUILDING, EAST NADA,
        THRISSUR ROAD, GURUVAYOOR, THRISSUR -680101
      </p>
    ),
  },
  {
    label: "CHALAKKUDI",
    desc: (
      <p>
        SWARNAM LAND MARK BUILDING, 1ST FLOOR, ROOM NO- 21/224-H7,H8, CHALAKUDI
        ANAMALA JUNCTION, CHALAKUDI, PIN CODE-680307
      </p>
    ),
  },
  {
    label: "KODUNGALLUR",
    desc: (
      <p>
        DR. K R ESWARAN’S BUILDING, 1ST FLOOR, ROOM NUMBER- 8/35/1,8/35/2,
        CHAPPARA, IRINJALAKUDA KODUNGALLUR ROAD, PULLUT P O, PIN CODE-680 663.
      </p>
    ),
  },
  {
    label: "PATTURAIKKAL",
    desc: (
      <p>
        SECOND FLOOR, ROOM NO. 28/85-55, CAPITAL TOWER, PATTURAIKKAL, THRISSUR-
        680022
      </p>
    ),
  },
  {
    label: "PATHANAMTHITTA",
    desc: (
      <p>
        FIRST FLOOR, ROOM NO. 164/2 VALIYAPARAMBIL BUILDING, ANCHAKKALA JUNCTION
        (RING ROAD), MELE VETTIPURAM, PATHANAMTHITTA P O, 689645
      </p>
    ),
  },
  {
    label: "KADAVOOR",
    desc: (
      <p>
        FIRST FLOOR,ROOM NO. 10/1457, MOHANZ RESIDENCY, DHANAM PLAZA, KADAVOOR,
        PERINAD P O,KOLLAM, 691601.
      </p>
    ),
  },
  {
    label: "ANCHAL",
    desc: (
      <p>
        FIRST FLOOR, MAJOR BAHSKARAN MEMORIAL BUILDING, ROOM NOS. 15/1529,
        15/1530 & 15/1531, ANCHAL MARKET JUNCTION, OPP. L P SCHOOL, ANCHAL WEST,
        ANCHAL P O, KOLLAM, 691306,
      </p>
    ),
  },
  {
    label: "KOTTARAKARA",
    desc: (
      <p>
        {" "}
        GROUND FLOOR, SREEPADMAM, 24/449-2 (NORTH EAST ROOM) & 24/449-4 (NORTH
        WEST ROOM), MYTHRI NAGAR, KIZHAKKEKARA, KOTTARAKARA, 691506,
      </p>
    ),
  },
  {
    label: "NEDUMANGAD",
    desc: (
      <p>
        {" "}
        FIRST FLOOR , ROYAL TOWER, ROOM NO 6/103, COLLEGE ROAD, VALICODE,
        NEDUMANGAD P O, THIRUVANANTHAPURAM, 695541.{" "}
      </p>
    ),
  },
  {
    label: "NEYYATTINKARA",
    desc: (
      <p>
        FIRST FLOOR , ROOM NO. 40/31, SWADESHABHIMANI SHOPPING COMPLEX, NEAR BUS
        STAND, NEYYATTINKARA, PIN-695121.{" "}
      </p>
    ),
  },
  {
    label: "KATTAKKADA",
    desc: (
      <p>
        GROUND FLOOR , ROOM NO. 17/606-A, VISWANATH BUILDING, P N M ROAD,
        KATTAKADA P O, THIRUVANANTHAPURAM, PIN -695572
      </p>
    ),
  },
  {
    label: "VEMBAYAM",
    desc: (
      <p>
        ROOM NO. 10/919 , MAJID PLAZA, KANYAKULANGARA, OPP. GOVT HOSPITAL,
        VEMBAYAM P O, THIRUVANANTHAPURAM, PIN- 695615.
      </p>
    ),
  },
  {
    label: "KILIMANOOR",
    desc: (
      <p>
        FIRST FLOOR, WARRIETH BUILDING, (BUILDING. NOS. 11/31 C & 11/31D), NEAR
        MAHADEVESWARAM TEMPLE, MAHADEVESWARAM, KILIMANOOR P O. TRIVANDRUM.
        PIN-695601,
      </p>
    ),
  },
  {
    label: "PERINTHALMANNA",
    desc: (
      <p>
        BUILDING NO: 32/1480, ROOM NO: 4, SECOND FLOOR, HOTEL MUGHAL PARK,
        SANGEETHA JUNCTION, CALICUT ROAD, PERINTHALMANNA, KERALA-679322. CONT-
        04933-291005
      </p>
    ),
  },
  {
    label: "CHITTUR",
    desc: (
      <p>
        FIRST FLOOR, SREELAKSHMI COMPLEX, ROOM NO. 17/33, ANIKODE, CHITTUR P O,
        NEAR FAMOUS BAKERY, PALAKKAD - 678101.
      </p>
    ),
  },
  {
    label: "KUZHALAMANNAM",
    desc: (
      <p>
        FIRST FLOOR, ROOM NO. 822/823/16, N H JUNCTION , KULAVAMOKU,
        KUZHALMANNAM P O , PALAKKAD, KERALA- PIN-678702
      </p>
    ),
  },
  {
    label: "MAYANNUR",
    desc: (
      <p>
         DAS ARCADE, ROOM NO: 361/02, NEAR PETROL PUMP, MAYANNUR P O,
        KERALA-679105.
      </p>
    ),
  },
  {
    label: "VADAKKENCHERRY",
    desc: (
      <p>
        FIRST FLOOR, NILGIRI COMPLEX, MUNDAPLOOR ROAD, OPPOSITE HERO HONDA
        SHOWROOM, MANGALAM PALAM, ANJUMOORTHI P O, VADAKKENCHERRY,
        PALAKKAD-678682. CONT: 0492-291660
      </p>
    ),
  },
  {
    label: "MAVELIKKARA",
    desc: (
      <p>
        FIRST FLOOR CITY PLAZA, PALLARIMANGALAM P.O, MAVELIKARA, THEKKEKKARA,
        ALAPPUZHA, KERALA. 690107.
      </p>
    ),
  },
  {
    label: "KALAVOOR",
    desc: (
      <p>
         NIZA CENTER, AYYANKALI JUNCTION, FIRST FLOOR, ROOM NO. 18/290/B,
        KALAVOOR MANNANCHERY PO PIN CODE: 688528.
      </p>
    ),
  },
  {
    label: "CHARUMOODU",
    desc: (
      <p>
        FIRST FLOOR VARENIVILAYIL, ROOM NO. 9/417-A ,KARIMULACKAL, KOMALLOOR
        (P.O), ALAPPUZHA, KERALA. PIN-690505.
      </p>
    ),
  },
  {
    label: "ERAMALLUR",
    desc: (
      <p>
        PALACKAL BUILDING, BUILDING NO:11/144A , ROOM SITUATED IN THE LEFT
        PORTION OF THE ENTRANCE, KODUMTHURUTHU , ALLEPPY DIST, KERALA -PIN
        688533
      </p>
    ),
  },
  {
    label: "BUND ROAD",
    desc: (
      <p>
        ARABIAN CENTER, GROUND FLOOR,ROOM NO: 2/343/E, EDAYAZHAM, KOTTAYAM PIN
        CODE: 686144
      </p>
    ),
  },
];

const Item = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: 200, // Fixed height
    width: 300, // Fixed width
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 'auto', // To center the items within the grid
  }));
  
  export default function BranchBox() {
    return (
      <div style={{ width: '100%' }}>
        <Container style={{ width: '100%', justifyContent: 'center' }}>
          <Box style={{ width: '100%', justifyContent: 'center', marginBottom: '20px', color:'#eb1f28' }}>
            <Typography variant="h4">Branch Address</Typography>
          </Box>
          <Box sx={{ flexGrow: 1, flexWrap: 'wrap' }}>
            <Grid container spacing={2} justifyContent="center">
              {branchList.map((item, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Item>
                    <h5  style={{color:"eb1f28",justifyContent:"center", textAlign: 'left'}}>{item.label}</h5>
                    <div style={{ fontSize: 12, textAlign: 'left' }}>{item.desc}</div>
                  </Item>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Container>
      </div>
    );
  }